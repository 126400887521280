var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.newCollections,"options":_vm.pagination,"server-items-length":_vm.totalNewCollections,"loading":_vm.loading,"footer-props":{
      'showFirstLastPage':true,
      'items-per-page-options': [10, 20, 50, 100]
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.start",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseDate(item.start))+" ")]}},{key:"item.end",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseDate(item.end))+" ")]}},{key:"item.shops",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.shops.length > 0 ? item.shops[0].name : "")+" "),_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [(item.shops.length > 1)?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},on),[_vm._v("mdi-dots-horizontal ")]):_vm._e()]}}],null,true)},[_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"500px"},attrs:{"dense":""}},_vm._l((item.shops),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1)]}},{key:"item.categories",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.categories.length > 0 ? item.categories[0].name : "")+" "),_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [(item.categories.length > 1)?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},on),[_vm._v("mdi-dots-horizontal ")]):_vm._e()]}}],null,true)},[_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"500px"},attrs:{"dense":""}},_vm._l((item.categories),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1)]}},{key:"item.cities",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.cities.length > 0 ? item.cities[0].name : "")+" "),_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [(item.cities.length > 1)?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},on),[_vm._v("mdi-dots-horizontal ")]):_vm._e()]}}],null,true)},[_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"500px"},attrs:{"dense":""}},_vm._l((item.cities),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1)]}},{key:"item.allCities",fn:function(ref){
    var item = ref.item;
return [_c('v-switch',{attrs:{"disabled":""},model:{value:(item.allCities),callback:function ($$v) {_vm.$set(item, "allCities", $$v)},expression:"item.allCities"}})]}},{key:"item.clear",fn:function(ref){
    var item = ref.item;
return [(_vm.currentUser.superAdmin || (!_vm.currentUser.superAdmin && !item.allCities))?_c('v-avatar',{attrs:{"color":"teal","size":"26","rounded":""}},[_c('router-link',{attrs:{"to":{
            name: 'NewCollectionEdit',
            params: { newCollectionId: item.id }
          }}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1)],1):_vm._e()]}},{key:"item.add",fn:function(ref){
          var item = ref.item;
return [(_vm.currentUser.superAdmin || (!_vm.currentUser.superAdmin && !item.allCities))?_c('v-avatar',{attrs:{"color":"red darken-4","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){return _vm.delRecord(item)}}},[_vm._v("mdi-delete")])],1):_vm._e()]}},{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-avatar',{attrs:{"color":"indigo","size":"30"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.page = 1;
                _vm.retrieveNewCollections();}}},[_vm._v("mdi-magnify ")])],1)],1),_c('td',[_c('router-link',{attrs:{"to":{ name: 'NewCollectionCreate' }}},[_c('v-avatar',{attrs:{"color":"teal","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)],1)],1)])]},proxy:true}])}),_c('ConfirmDialog',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }